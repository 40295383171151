<template>
  <v-container class="px-0 px-sm-3">
    <ApolloQuery :query="RESIDENCES_WITH_VISIBLE_USERS" :update="(data) => data.me.residencesWithVisibleUsers">
      <template v-slot="{ result: { data: residences, loading } }">
        <div v-if="!loading">
          <residence-users
            v-for="residence in residences"
            :key="residence.id"
            class="mb-3"
            :ref="'residence-' + residence.id"
            :residence="residence"
            :exclude-types="['candidate']"
            @userChangedResidence="refetchResidenceUsers"
          />
        </div>
        <v-skeleton-loader v-else type="card" />
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import ResidenceUsers, { RESIDENCE_USERS_FRAGMENT } from "../components/users/ResidenceUsers.vue";

const RESIDENCES_WITH_VISIBLE_USERS = gql`
  query ManagedResidences {
    me {
      id
      residencesWithVisibleUsers {
        id
        ...residenceUsersFragment
      }
    }
  }
  ${RESIDENCE_USERS_FRAGMENT}
`;

export default {
  components: { ResidenceUsers },

  name: "Users",

  props: {
    status: {
      type: String,
      default: "active",
    },
  },

  data() {
    return {
      RESIDENCES_WITH_VISIBLE_USERS,
    };
  },

  methods: {
    refetchResidenceUsers({ id }) {
      const residenceComponent = this.$refs[`residence-${id}`][0];
      residenceComponent.refetch();
    },
  },
};
</script>
